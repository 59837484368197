import React from "react";
import _ from "lodash";

import { withPrefix, markdownify, htmlToReact } from "../utils";

export default class SectionSupported extends React.Component {
  render() {
    let section = _.get(this.props, "section", null);
    return (
      <section
        id={_.get(section, "section_id", null)}
        className="block bg-accent outer supported-hero"
      >
        {_.get(section, "title", null) && (
          <h2 className="block-title">{_.get(section, "title", null)}</h2>
        )}

        <div class="supported-animation">
          <div class="supported-first">
            <img src="images/ebay.png" />
          </div>
          <div>
            <img src="images/grailed.png" />
          </div>
          <div>
            <img src="images/depop.png" />
          </div>
          <div>
            <img src="images/bump.png" />
          </div>
          <div>
            <img src="images/etsy.png" />
          </div>
          <div>
            <img src="images/bonanza.png" />
          </div>
          <div>
            <img src="images/shopify.png" />
          </div>
        </div>

        {/* <div className="inner">
          <div className="grid">
            <div className="cell block-content">
              <div className="block-copy">
                <div class="supported-animation">
                  <div class="supported-first">
                    <img src="images/ebay.png" />
                  </div>
                  <div>
                    <img src="images/grailed.png" />
                  </div>
                  <div>
                    <img src="images/depop.png" />
                  </div>
                  <div>
                    <img src="images/bump.png" />
                  </div>
                  <div>
                    <img src="images/etsy.png" />
                  </div>
                  <div>
                    <img src="images/bonanza.png" />
                  </div>
                  <div>
                    <img src="images/shopify.png" />
                  </div>
                </div>
                {markdownify(_.get(section, "content", null))}
              </div>
            </div>
          </div>
        </div> */}
      </section>
    );
  }
}
